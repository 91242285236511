.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3442df;
    width: 30px;
    border-radius: 50%;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}